.Header {
    position: fixed;
    top: 0; /* Stick it to the top */
    max-height: 70px;
    width: 100vw;
    z-index: 10;
    display: grid;
    grid-template-areas: "logo nav";
    height: 70px;
    /* Cosmetics */
    background-color: #46a5de;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .Logo {
    grid-area: logo;
    height: 70px;
  }
  
  .Nav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    justify-items: center;
  }
  .Nav a {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
  }
  .Nav a:hover {
    transform: scale(1.1);
  }
  .Nav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: 70ms;
  }
  
  .Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
  }
  
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
  }
  .Burger:active {
    transform: scale(1.2);
  }
  
  button.Burger {
    color: white;
    padding-right: 10px;
    outline:none;
}
  @media (max-width: 700px) {
    .Header {
      grid-template-areas: "logo burger" "nav nav";
    }
    .Nav {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 20px;
  
      padding: 30px 0 30px;
      background: rgba(40, 44, 47, 0.95);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .Burger {
      display: inline;
    }
  }
  
  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }
  