.events {
  background-image: none !important;
}

.bio_section .content-container {
  padding: 40px 0px 10px;
}
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
  text-align: left;
}

.bio_section .title-style-one {
  margin-bottom: 40px;
}
.title-style-one {
  position: relative;
  margin-bottom: 20px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.bio_section .timeline-column {
  position: relative;
  margin-bottom: 80px;
}
.bio_section .timeline-column .inner {
  position: relative;
  padding-left: 39px;
  padding-bottom: 2px;
}

.bio_section .timeline-column .inner:before {
  content: "";
  position: absolute;
  left: 38px;
  top: 20px;
  border-left: 1px solid #d0d0d0;
  height: 100%;
}
.bio_section .timeline-column .col-header {
  position: relative;
  padding: 25px 0px 25px 64px;
  min-height: 80px;
  margin-bottom: 45px;
}
.bio_section .timeline-column .col-header h2{
  text-align: left;
  font-size: 2.5em;
  font-weight: bold;
}
.bio_section .timeline-column .col-header .icon-box {
  position: absolute;
  left: -47px;
  top: 0px;
  width: 92px;
  height: 92px;
  padding: 20px 20px;
}

.bio_section .timeline-column .col-header .icon-box .icon-inner {
  position: relative;
  width: 52px;
  height: 52px;
}

.bio_section .timeline-column .col-header .icon-box .icon {
  position: relative;
  display: block;
  width: 52px;
  height: 52px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  line-height: 52px;
  z-index: 1;
}
.bio_section .timeline-column .col-header .icon-box .icon-inner:after {
  content: "";
  position: absolute;
  background: #0d47a1;
  display: block;
  left: 0px;
  top: 0px;
  width: 52px;
  height: 52px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.bio_section .timeline-column .timeline-block {
  position: relative;
  margin-bottom: 42px;
  text-align: left;
}

.bio_section .timeline-column .timeline-block .inner-box {
  position: relative;
  padding-left: 30px;
}

.bio_section .timeline-column .timeline-block .date {
  position: relative;
  display: inline-block;
  margin-left: -15px;
  margin-bottom: 16px;
  line-height: 24px;
  font-size: 14px;
  color: #777777;
  font-weight: 400;
  padding: 0px 18px;
}

.bio_section .timeline-column .timeline-block .date:before {
  content: "";
  position: absolute;
  left: -21px;
  top: 4px;
  width: 11px;
  height: 11px;
  background: #0d47a1;
  border-radius: 50%;
}

.bio_section .timeline-column .timeline-block h4 {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 7px;
}

.bio_section .timeline-column .timeline-block h3 {
    font-size: 14px;
    color: #777777;
    font-weight: 600;
    margin-bottom: 7px;
  }

.bio_section .timeline-column .timeline-block .text {
  font-size: 14px;
}

.bio_section .timeline-column .timeline-block .text ul {
  list-style: outside;
  margin-left: 15px;
}

.bio_section .timeline-column .timeline-block {
  position: relative;
  margin-bottom: 42px;
}

.bio_section .timeline-column .timeline-block .inner-box {
  position: relative;
  padding-left: 30px;
}


.credential{
  padding-top: 8px;
}


.content-skills button {
  color: #39739d;
  background-color: #e1ecf4;
  border-color: #e1ecf4;
  cursor: default;
  display: inline-block;
  padding: .4em .5em;
  margin: 2px 2px 2px 0;
  font-size: 11px;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  transition: all .15s ease-in-out;
}

.content-skills button:focus {outline:0;}