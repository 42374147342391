.skills{
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
    padding: 0px;
    float: none;
    width: 152px;
}
.skills h4{

    padding: 10px;
    font-weight: 700;
}

.chart-inline{
    display: inline-block;
}

.section-banner-box {
    position: relative;
    padding: 70px 0px;
    color: #ffffff;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-image: url(../assets/images/bg.jpg);
}


.section-banner-box:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: url(../assets/images/dotted-overlay.png) center center repeat;
    background-color: rgba(0,0,0,0.30);
}


.section-banner-box .auto-container {
    position: relative;
    z-index: 1;
}
.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
    text-align: center;
}

.section-banner-box h1 {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 900;
    color: #ffffff;
    letter-spacing: 1px;
    line-height: 100px;
}

.section-banner-box h1 .bg-text {
    position: relative;
    display: inline-block;
    background: #0091ea;
    padding: 0px 10px;
    margin: 0px 10px;
}

.section-banner-box h1 .lined-text {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #ffffff;
}
.section-banner-box h1 .lined-text:before {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -3px;
    width: 24px;
    border-bottom: 5px solid #0091ea;
}
.skills-donut-chart svg text:nth-child(3) {
    display: none;
}
@media only screen and (max-width: 1023px){

    .main-content-box .auto-container {
        padding: 0px 20px;
    }
}
@media only screen and (max-width: 1279px) {

    .main-content-box .auto-container {
        padding: 0px 50px;
    }
}
.section-banner-box .auto-container {
    position: relative;
    z-index: 1;
}
.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .section-banner-box {
        margin: 10px;
    }
}