.tile {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.tile h3 {
  font-weight: bold;
}

.description-portfolio {
  padding-bottom: 35px;
}

.more-info {
  position: absolute;
  width: 80%;
  bottom: 40px;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.btn-primary {
  color: white;
  background-color: #46a5de;
}

.btn-primary:hover {
  color: white;
  background-color: #3a90c5;
  border-color: #3a90c5;
}

.list .list-only {
  display: auto;
}

.list .grid-only {
  display: none !important;
}

.grid .grid-only {
  display: auto;
}

.grid .list-only {
  display: none !important;
}

.pull-right {
  margin-bottom: 40px;
}

.portfolio {
  padding-top: 30px;
  padding-bottom: 30px;
}

.content-skills {
  padding: 10px 0px;
}