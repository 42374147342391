.contactDetails {
    background: #474d5d9e;
    margin-top: 40px;
}

.contactDetails h4 {
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.contactDetails p {
    font-size: 13px;
    line-height: 19px;
    padding-bottom: 14px;
}

.conForm input {
    background: #585f73;
    color: #93959c;
    padding: 15px 30px;
    border: none;
    margin-right: 3%;
    margin-bottom: 30px;
    outline: none;
    font-style: italic;
}

.conForm textarea {
    background: #585f73;
    color: #93959c;
    padding: 15px 30px;
    border: none;
    margin-bottom: 40px;
    outline: none;
    height: 100px;
    font-style: italic;
}

.conForm .submitBnt {
    background: #ff675f;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    float: right;
    margin: 0px;
}
.contact-container {
    padding: 50px;
}

.contact-container h3 {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
}
.contact-info ul {
    text-align: justify;
    line-height: 35px;
    font-size: 18px;
    padding-top: 10px;
}
.contact-info {
    padding: 10px 15px;
}
.contact-info p {
    color: white;
    font-size: 18px;
    text-align: center;
    padding-top: 30px;
}

.contact-info ul.icons svg {
    color: #0091ea;
    margin-right: 15px;
}

.social-icons li {
    display: inline-block;
    padding: 10px;
}
.social-icons li a {
    font-size: 40px;
    color: #0091ea;
}
.contact-info .icons a {
    color: white;
}

.contact-info .icons a:hover {
    text-decoration: none;
}

.contact-info .social-icons {
    text-align: center;
}
.contact-info .icons li:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {
    .contact-info p {
        padding-top: 60px;
    }
}