
@font-face {
	font-family: 'Open Sans', 'sans-serif' !important;
	src: url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
}

@import 'styles/slider.css';
@import 'styles/carousel.css';
@import 'styles/experience.css';
@import 'styles/skills.css';
@import 'styles/contact.css';
@import 'styles/header.css';
@import 'styles/portfolio.css';
@import 'styles/footer.css';
@import 'styles/notfound.css';
@import 'styles/style.css';
