.not-found-container {
    background-color: #2D72D9;
    color: #fff;
    width: 100%;
    height: 100vh;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
}

.error-container {
    text-align: center;
    height: 100%;
}

.error-container h1 {
    margin: 0;
    font-size: 130px;
    font-weight: 300;
}

.error-container a {
   color: white;
   font-weight: bold;
}
.return {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    letter-spacing: -0.04em;
    margin: 0;
}

.error-container h1 {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.return {
    position: absolute;
    width: 100%;
    bottom: 30%;
}

@media (min-width: 480px) {
    .return {
        position: absolute;
        width: 100%;
        bottom: 30px;
    }
}

@media (min-width: 768px) {
    .error-container h1 {
        font-size: 220px;
    }
}